<template>
  <section class="section-two" id="pricing">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 text-center">
          <div class="section-title mb-4 pb-2">
            <h4 class="title mb-4">Comfortable Rates Protection Level</h4>
            <p class="text-muted para-desc mx-auto mb-0">
              <span class="text-primary font-weight-bold">AIG</span>. Sign up in seconds. Get paid in minutes.
              Award-winning service.
            </p>
          </div>
        </div>
      </div>
      <Plans/>
    </div>
  </section>
</template>

<script>
  import Plans from "@/components/home/Plans";
  export default {
    components: {
      Plans,
    },
  };
</script>